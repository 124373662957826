const iRPMBaseURL = process.env.VUE_APP_IRPM_BASE_URL
const iRPMAppName = process.env.VUE_APP_IRPM_APP_NAME
const iRPMProj = process.env.VUE_APP_IRPM_PROJ_NAME
const iRPMEnv = process.env.VUE_APP_IRPM_ENV
const proxy = process.env.VUE_APP_PROXY

const retryLimit = 3
const backoffInterval = 3000

class AppException extends Error {
  constructor (code, message) {
    const fullMessage = message ? `${code}: ${message}` : code
    super(fullMessage)
    this.name = code
    this.code = code
    this.message = message
  }
}

export default {
  async sendGetRequest (url, iteration = 1) {
    const jwt = localStorage.getItem('jwt')
    const headers = {
      'Content-Type': 'application/json'
    }
    if (jwt != null){
      headers["Authorization"] = jwt
    }
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers
      })
      if (!response.ok) {
        throw new AppException(response.status, await response.json())
      }
      return {
        status: "OK",
        data: await response.json()
      }
    }
    catch (error) {
      console.log(error, backoffInterval)
      if (iteration === retryLimit) {
        return {
          status: "ERROR",
          error,
          code: error.code,
          data: error.message
        }
      }

      return new Promise(resolve => {
        const delay = iteration * backoffInterval
        console.log(`Retry ${iteration}, delay at ${delay}`)
        setTimeout(() => {
          this.sendGetRequest(url, iteration + 1).then(resolve)
        }, delay)
      })
    }
  },
  async sendPostRequest (url, data, iteration = 1) {
    const jwt = localStorage.getItem('jwt')
    const headers = {
      'Content-Type': 'application/json'
    }
    if (jwt != null){
      headers["Authorization"] = jwt
    }
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        mode: 'cors',
        body: JSON.stringify(data)
      })
      if (!response.ok) {
        throw new AppException(response.status, await response.json())
      }
      return {
        status: "OK",
        data: await response.json()
      }
    }
    catch (error) {
      console.log(error, backoffInterval)
      if (iteration === retryLimit) {
        return {
          status: "ERROR",
          error,
          code: error.code,
          data: error.message
        }
      }

      return new Promise(resolve => {
        const delay = iteration * backoffInterval
        console.log(`Retry ${iteration}, delay at ${delay}`)
        setTimeout(() => {
          this.sendPostRequest(url, data, iteration + 1).then(resolve)
        }, delay)
      })
    }
  },
  go2safe() {
    let name = encodeURIComponent(iRPMAppName)
    let callbackUrl = encodeURIComponent(window.location.origin + '/jwt/${jti}/${nounce}')
    let proj = iRPMProj
    let gate = iRPMBaseURL+'/console/safe.html'
    window.location.href = gate + '#/name/' + name + '/proj/' + proj + '/callback/' + callbackUrl
  },
  fetchJWT(jti, nounce) {
    return this.sendGetRequest(iRPMBaseURL+'/' + iRPMEnv + '/api/get-jwt/' + JSON.stringify({ jti: jti, nounce: nounce }),)
  },
  queryExecution(payload){
    return this.sendPostRequest(proxy+"v1/query-executions", payload)
  },
  queryResult(resultId){
    return this.sendGetRequest(proxy+"v1/query-executions/"+resultId+"/result")
  }
}

