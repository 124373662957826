<template>
  <div id="app">
    <ef-loader v-if="!ready"></ef-loader>
    <template v-else>
      <template v-if="loggedOut">
        <ef-panel class="login" spacing>
          <ef-layout flex container>
            <ef-layout>
              <img class="logo" src="@/assets/refinitiv.logo.strapline.svg" alt="Logo">
            </ef-layout>
            <ef-layout>
              <h3>Logged out successfully!</h3>
            </ef-layout>
            <ef-layout>
              <p>If you want to re-login, please refresh the page!</p>
            </ef-layout>
          </ef-layout>
        </ef-panel>
      </template>
      <template v-if="loggedIn">   
        <div class="overlay" v-if="loading">
          <ef-loader></ef-loader>
        </div>
        <header>
          <nav class="main">
            <ul>
              <li><a class="logo" href="#"><span>Refinitiv</span></a></li>
              <li class="logout"><ef-button class="large" cta @click="logout">Logout</ef-button></li>
            </ul>
          </nav>
        </header>
        <ef-sidebar-layout ref="mainLayout" :sidebar-width="sidebarWidthPx">
          <ef-header slot="sidebar-header" level="1">Controls</ef-header>
          <ef-panel slot="sidebar-content" spacing>
            <ul class="input-panel">
              <li>
                <label>Asset Class:</label><br>
                <ef-toggle class="full" label="Equities" checked-label="Fixed Income" readonly></ef-toggle>
              </li>
              <li>
                <label>Date:</label><br>
                <ef-datetime-picker class="full" ref="datePicker" format="dd-MMM-yyyy" :value="selectedDate"></ef-datetime-picker>
              </li>
              <li>
                <label>Market:</label><br>
                <ef-tree-select class="full" ref="markets" placeholder="Select a market" multiple></ef-tree-select>
              </li>
              <li>
                <label>Search Criteria:</label><br>
                <ef-combo-box class="full" ref="criteria" v-on:value-changed="enableSearch" placeholder="Select your search type..."></ef-combo-box>
              </li>
              <li>
                <label>Search:</label><br>
                <ef-text-field class="full" ref="search" placeholder="RIC, CUSIP, ISIN, SEDOL, NAME" disabled></ef-text-field>
              </li>
              <li>
                <ul class="horizontal-option">
                  <li>
                    <label>Highlight Color:</label><br>
                    <ef-color-picker ref="highlightColor" :value="highlightColor"></ef-color-picker>
                  </li>
                  <li>
                    <label>Font Color:</label><br>
                    <ef-color-picker ref="fontColor" :value="fontColor"></ef-color-picker>
                  </li>
                </ul>
              </li>
              <li>
                <ef-button cta v-on:click="saveSelectedValues">Apply</ef-button>
                &nbsp;
                <ef-button cta v-on:click="resetSelectedValues">Reset</ef-button>
              </li>
            </ul>
          </ef-panel>
          <ef-header slot="main-header" level="1">
            <ef-button transparent slot="left" ref="mainLayoutToggleButton" icon="leftpanel-open" v-on:click="mainLayoutToggleButtonClicked"></ef-button>
            <ef-toggle class="w150 toolbar-element" slot="right" label="Initialization" checked-label="Delta"></ef-toggle>
            <ef-select class="toolbar-element" slot="right">
              <ef-item selected>All</ef-item>
              <ef-item v-for="(date, index) in dateRange" :key="index" :value="date.value">{{ date.text }}</ef-item>
            </ef-select>
            <ef-button class="toolbar-element" slot="right" transparent icon="export" title="Export data" v-on:click="toBeImplement"></ef-button>
          </ef-header>
          <ef-panel slot="main-content">
            <p v-if="!equityResults" class="no-results-p">No results found</p>
            <efx-grid v-if="equityResults" ref="instrumentsGrid"></efx-grid>
            <ef-overlay class="popup" ref="instrumentDialog" with-backdrop no-cancel-on-outside-click>
              <ef-layout container>
                <ef-header class="full" level="1">{{ selectedInstrumentName }}
                  <ef-button slot="right" title="Close" icon="cross" transparent :disabled="loading" @click="closePopup()"></ef-button>
                </ef-header>
                <ef-tab-bar>
                  <ef-tab label="Reference" :active="referenceActive" @click="switchTab($event)" :disabled="loading"></ef-tab>
                  <ef-tab label="Corporate Actions" :active="corporateActionsActive" @click="switchTab($event)" :disabled="loading"></ef-tab>
                  <ef-tab label="Pricing" :active="pricingActive" @click="switchTab($event)" :disabled="loading"></ef-tab>
                </ef-tab-bar>
                <section v-if="referenceActive">
                  <ef-header level="2">Fields</ef-header>
                  <ef-layout :max-height="topPanelHeight" scrollable>
                    <div>
                      <table class="info-table" v-if="refFieldsFlag">
                        <tbody>
                          <tr v-for="(row, rowIndex) in equityReferenceFieldData()" :key="rowIndex">
                            <template v-for="(column, columnIndex) in row">
                              <td style="text-transform: uppercase;" v-if="columnIndex % 2 === 0" class="header" :key="'header' + columnIndex">{{ column }}</td>
                              <td v-if="columnIndex % 2 === 1" class="value" :key="'data' + columnIndex" :style="highlightStyles('sampleEquityReferenceChangeFields', row[columnIndex-1])">{{ column }}</td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </ef-layout>
                  <ef-header level="2">Changes</ef-header>
                  <ef-layout>
                    <p v-if="!refChangesFlag" class="no-results-p">There are no changes in the fields</p>
                    <efx-grid v-if="refChangesFlag" ref="referenceChangesGrid" :height="bottomPanelHeight"></efx-grid>
                  </ef-layout>
                </section>
                <section v-if="corporateActionsActive">
                  <ef-tab-bar>
                    <ef-tab label="Reference" :active="corporateActionsReferenceActive" @click="switchCorporateActionsTab($event)"></ef-tab>
                    <ef-tab label="Events" :active="corporateActionsEventsActive" @click="switchCorporateActionsTab($event)"></ef-tab>
                  </ef-tab-bar>
                  <section v-if="corporateActionsReferenceActive">
                    <ef-header level="2">Fields</ef-header>
                    <ef-layout :max-height="topPanelHeight" scrollable>
                      <div>
                        <table class="info-table">
                          <tbody>
                            <tr v-for="(row, rowIndex) in equityCorporateActionsReferenceFieldRows" :key="rowIndex">
                              <template v-for="(column, columnIndex) in row">
                                <template>
                                  <td class="header" :key="'header' + columnIndex">{{ column }}</td>
                                  <td class="value" :key="'data' + columnIndex" :style="highlightStyles('sampleEquityCorporateActionsReferenceChangeFields', column)">Sample value</td>
                                </template>
                              </template>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </ef-layout>
                    <ef-header level="2">Changes</ef-header>
                    <ef-layout>
                      <efx-grid ref="corporateActionsReferenceChangesGrid" :height="bottomPanelHeight"></efx-grid>
                    </ef-layout>
                  </section>
                  <section v-if="corporateActionsEventsActive">
                    <efx-grid ref="corporateActionsEventsGrid" :height="topEventPanelHeight"></efx-grid>
                    <ef-header level="2">Changes</ef-header>
                    <efx-grid ref="corporateActionsEventChangesGrid" :height="bottomEventPanelHeight"></efx-grid>
                  </section>
                </section>
                <section v-if="pricingActive">
                  <ef-layout :max-height="pricingGridHeight"><ef-interactive-chart v-if="priceChartFlag" id="pricing_chart"></ef-interactive-chart></ef-layout>
                  <ef-layout :max-height="pricingGridHeight" style="overflow-y: scroll;"><efx-grid ref="pricingGrid"></efx-grid></ef-layout>
                </section>
              </ef-layout>
            </ef-overlay>
          </ef-panel>
        </ef-sidebar-layout>
      </template>
    </template>
  </div>
</template>

<script>
import RowGrouping from 'tr-grid-row-grouping/es6/RowGrouping'
import RowSelection from 'tr-grid-row-selection/es6/RowSelection'
import ContentWrap from 'tr-grid-content-wrap/es6/ContentWrap'
import TitleWrap from 'tr-grid-titlewrap/es6/TitleWrap'
import staticFetch from '@/services/staticFetch'
import dataServices from '@/services/dataServices'
import { info, error } from '@refinitiv-ui/elements/notification';
import moment from 'moment'
import equityPayload from './payloads/equity.json'
import pricePayload from './payloads/price.json'
import refFieldsPayload from './payloads/ref_fields.json'
import refChangesPayload from './payloads/ref_changes.json'
import refChangesFields from '../public/data/reference-changes.json'

export default {
  name: 'App',
  components: {
  },
  computed: {
    sidebarWidthPx () {
      return `${this.sidebarWidth}px`
    },
    topPanelHeight () {
      const windowHeight = window.innerHeight
      const height = ((windowHeight * 0.8) * 0.4) - 28  // 28: header
      return `${height}px`
    },
    topEventPanelHeight () {
      const windowHeight = window.innerHeight
      const height = ((windowHeight * 0.8) * 0.6)
      return `${height}px`
    },
    bottomPanelHeight () {
      const windowHeight = window.innerHeight
      const height = ((windowHeight * 0.8) * 0.6) - 92  // 28 + 28 + 36: headers and tabs
      return `${height}px`
    },
    bottomEventPanelHeight () {
      const windowHeight = window.innerHeight
      const height = ((windowHeight * 0.8) * 0.4) - 128  // 28 + 28 + 36 + 36: headers and tabs
      return `${height}px`
    },
    pricingGridHeight () {
      const windowHeight = window.innerHeight
      const height = ((windowHeight * 0.8) * 0.5) - 36  // 28 + 28 + 36 + 36: headers and tabs
      return `${height}px`
    },
    popupContentHeight () {
      return `${(window.innerHeight * 0.8) - 64}px`
    },
    equityReferenceFieldRows () {
      const rows = []
      let columns = []
      this.equityReferenceFields.forEach((field, index) => {
        columns.push(field)
        if (index % 2 === 1) {
          rows.push(columns)
          columns = []
        }
      })
      if (columns.length > 0 && rows[rows.length - 1] != columns) {
        rows.push(columns)
      }
      return rows
    },
    sampleEquityReferenceChangeFields () {
      return this.sampleEquityReferenceChanges.map(row => row[2])
    },
    equityCorporateActionsReferenceFieldRows () {
      const rows = []
      let columns = []
      this.equityCorporateActionsReferenceFields.forEach((field, index) => {
        columns.push(field)
        if (index % 2 === 1) {
          rows.push(columns)
          columns = []
        }
      })
      if (columns.length > 0 && rows[rows.length - 1] != columns) {
        rows.push(columns)
      }
      return rows
    },
    sampleEquityCorporateActionsReferenceChangeFields () {
      return this.sampleEquityCorporateActionsReferenceChanges.map(row => row[2])
    },
    dateRange () {
      const dates = []
      for (let i = 0; i < 7; i++) {
        const date = moment(this.selectedDate).add(i, 'd')
        dates.push({
          value: date,
          text: date.format(this.dateFormat)
        })
      }
      return dates
    }
  },
  watch: {
    loggedIn () {
      if (this.loggedIn) {
        this.$nextTick(() => {
          this.$refs.markets.data = this.markets
          this.$refs.criteria.data = this.criteria
          this.getLastSaturday()
          this.requestType = "equity"
          var payload = equityPayload
          payload["dateConditions"][0]["startDate"] = this.selectedDate
          payload["dateConditions"][0]["endDate"] = this.todayDate
          this.postQueryExecution(payload)
        })
      }
    }
  },
  data () {
    return {
      equityResults: true,
      refChangesFlag: true,
      ready: false,
      loading: false,
      loggedIn: false,
      loggedOut: false,
      sidebarWidth: 200,
      query_request_delay: 5000,
      selectedInstrumentName: '',
      selectedQuotePermId: '',
      selectedIssuePermId: '',
      selectedRIC: '',
      equityColumns: [
        {'title': 'Instrument', 'field': 'int_ric', 'sortBy': 'int_ric', 'minWidth': 100},
        {'title': 'Security Description', 'field': 'int_security_description', 'sortBy': 'int_security_description', 'minWidth': 150, contentWrap: true},
        {'title': 'Company Short Name', 'field': 'int_company_short_name', 'sortBy': 'int_company_short_name', 'minWidth': 150},
        {'title': 'Asset Status', 'field': 'int_asset_status', 'sortBy': 'int_asset_status', 'minWidth': 100},
        {'title': 'Currency Code', 'field': 'int_currency_code', 'sortBy': 'int_currency_code', 'minWidth': 100},
        {'title': 'CUSIP', 'field': 'int_cusip', 'sortBy': 'int_cusip', 'minWidth': 100},
        {'title': 'ISIN', 'field': 'int_isin', 'sortBy': 'int_isin', 'minWidth': 100},
        {'title': 'SEDOL', 'field': 'int_sedol', 'sortBy': 'int_sedol', 'minWidth': 100},
        {'title': 'Refinitiv Classification Scheme', 'field': 'int_trbc_industry_code', 'sortBy': 'int_trbc_industry_code', 'width': 150, contentWrap: true},
        {'title': 'Share Class', 'field': 'int_share_class', 'sortBy': 'int_share_class', 'minWidth': 100},
        {'title': 'Quote Perm Id', 'field': 'int_quote_perm_id', 'sortBy': 'int_quote_perm_id', 'minWidth': 100},
        {'title': 'Issue Perm Id', 'field': 'int_issue_perm_id', 'sortBy': 'int_issue_perm_id', 'minWidth': 100}
      ],
      equityData: [],
      refFieldsFlag: false,
      refFieldsData: [],
      refChangesData: [],
      equityReferenceChangeColumns: [
        {'title': 'Publish Date', 'field': 'timestamp', 'sortBy': 'timestamp', 'width': 200},
        {'title': 'Action', 'field': 'action', 'sortBy': 'action', 'width': 150},
        {'title': 'Field', 'field': 'field', 'sortBy': 'field', 'minWidth': 250},
        {'title': 'Previous', 'field': 'previous', 'minWidth': 250},
        {'title': 'Current', 'field': 'current', 'minWidth': 250}
      ],
      equityPricingColumns: [
        {'title': 'Trade Date', 'field': 'timestamp', 'sortBy': 'timestamp', 'width': 200},
        {'title': 'Ask', 'field': 'askPrice', 'sortBy': 'askPrice', 'minWidth': 100},
        {'title': 'Bid', 'field': 'bidPrice', 'sortBy': 'bidPrice', 'minWidth': 100},
        {'title': 'Open', 'field': 'openPrice', 'sortBy': 'openPrice', 'minWidth': 100},
        {'title': 'High', 'field': 'highPrice', 'sortBy': 'highPrice', 'minWidth': 100},
        {'title': 'Low', 'field': 'lowPrice', 'sortBy': 'lowPrice', 'minWidth': 100},
        {'title': 'Close', 'field': 'closePrice', 'sortBy': 'closePrice', 'minWidth': 100},
        {'title': 'Volume', 'field': 'volume', 'sortBy': 'volume', 'minWidth': 100}
      ],
      pricingData: [],
      equityCorporateActionsEventColumns: [
        {'title': 'Event', 'field': 'eventId', 'sortBy': 'eventId', 'width': 120},
        {'title': 'Level', 'field': 'level', 'sortBy': 'level', 'width': 50},
        {'title': 'Partitions', 'field': 'partitions', 'sortBy': 'partitions', 'minWidth': 150},
        {'title': 'Parent', 'field': 'parent', 'sortBy': 'parent', 'width': 70, contentWrap: true},
        {'title': 'Sequence Number', 'field': 'sequenceNumber', 'sortBy': 'sequenceNumber', 'width': 80, 'alignment': 'right'},
        {'title': 'Adjustment Factor', 'field': 'adjustmentFactor', 'sortBy': 'adjustmentFactor', 'minWidth': 100, contentWrap: true},
        {'title': 'Announcement Date', 'field': 'announcementDate', 'sortBy': 'announcementDate', 'width': 120, contentWrap: true},
        {'title': 'Effective Date', 'field': 'effectiveDate', 'sortBy': 'effectiveDate', 'width': 120, contentWrap: true},
        {'title': 'Meeting Date', 'field': 'meetingDate', 'sortBy': 'meetingDate', 'width': 120, contentWrap: true},
        {'title': 'Meeting End Date', 'field': 'meetingEndDate', 'sortBy': 'meetingEndDate', 'width': 120, contentWrap: true},
        {'title': 'Meeting Agenda Type Code Date', 'field': 'meetingAgencyTypeCode', 'sortBy': 'meetingAgencyTypeCode', 'minWidth': 150, contentWrap: true},
        {'title': 'Ex Date', 'field': 'exDate', 'sortBy': 'exDate', 'width': 120, contentWrap: true},
        {'title': 'Payment Date', 'field': 'paymentDate', 'sortBy': 'paymentDate', 'width': 120, contentWrap: true}
      ],
      referenceActive: true,
      corporateActionsActive: false,
      pricingActive: false,
      corporateActionsReferenceActive: true,
      corporateActionsEventsActive: false,
      equityReferenceFields: [],
      sampleEquityReferenceChanges: [],
      equityCorporateActionsReferenceFields: [],
      sampleEquityCorporateActionsReferenceChanges: [],
      equityCorporateActionsEventFields: [],
      sampleEquityCorporateActionsEventChanges: [],
      dateFormat: 'D MMM YYYY',
      selectedDate: '2023-05-13',
      todayDate: '2023-05-13',
      markets: [
        { label: 'Hong Kong', value: 'HK' },
        { label: 'Japan', value: 'JP' },
        { label: 'Singapore', value: 'SG' },
        { label: 'Thailand', value: 'TH' },
        { label: 'USA', value: 'US' },
        { label: 'Australia', value: 'AU' },
        { label: 'Canada', value: 'CA' },
        { label: 'France', value: 'FR' },
        { label: 'Germany', value: 'GE' },
        { label: 'Indonesia', value: 'IN' },
        { label: 'London', value: 'LN' },
        { label: 'Philippines', value: 'PH' },
        { label: 'Switzerland', value: 'SW' },
        { label: 'Netherlands', value: 'NL' },
        { label: 'India', value: 'IND'}
      ],
      criteria: [
        { label: "RIC", value: "0" },
        { label: "ISIN", value: "3" },
        { label: "CUSIP", value: "4" },
        { label: "SEDOL", value: "5" },
        { label: "NAME", value: "7" },
      ],
      selectedMarket: '',
      highlightColor: '#001EFF',
      fontColor: '#FFFFFF',
      appName: 'dsp-tool',
      jwt: "",
      currentUser: "",
      requestType: "",
      rowClickFlag: [0,0,0],
      priceChartFlag: false,
      promiseFlag: 0,
      refPromise: 0
    }
  },
  methods: {
    getLastSaturday() {
      let d = new Date()
      d.setDate(d.getDate() - (d.getDay() + 1))
      this.selectedDate = d.toJSON().slice(0, 10)
      this.todayDate = new Date().toJSON().slice(0, 10)
    },
    onKeyup (event) {
      if (event.target.id === 'username' && event.key === 'Enter') {
        this.$refs.password.focus()
      }
      else if (event.target.id === 'password' && event.key === 'Enter') {
        this.login()
      }
    },
    redirectUserLogin() {
      dataServices.go2safe()
    },
    fetchResponse(){
      let uri = window.location.href.split('/');
      if(uri.length>4 && !this.loggedIn){
        dataServices.fetchJWT(uri[4],uri[5]).then((data) => {
          // console.log(data["data"])
          if(data["data"]["d"]){
            this.jwt = data["data"]["d"]["jwt"]
            localStorage.setItem('jwt', this.jwt)
            this.extract(this.jwt)
            window.location.href = uri[0]+"//"+uri[2]
          }
        })
      }
    },
    extract(jwt) {
      let parts = jwt.split('.')
      let bodyEnc = parts[1]
      let base64 = bodyEnc.replace(/-/g, '+').replace(/_/g, '/')
      if (!base64) {
        return
      }
      let bodyStr = atob(base64)
      let body
      try {
          body = JSON.parse(bodyStr)
      }
      catch (e) {
        body = {}
      }
      let exp = body.exp
      // console.log(exp)
      let user = body.usr
      // console.log(user)

      if (!this.isExpired(exp)) {
        this.currentUser = user
        this.loggedIn = true
        this.dismissNotification()
      }
      else {
        this.currentUser = null
        this.loggedIn = false
        localStorage.removeItem('jwt')
        this.redirectUserLogin()
      }
    },
    isExpired(exp) {
        if (!exp) return true;
        let now = Date.now();
        return now >= exp * 1000;
    },
    login () {
      const regEx = RegExp(/[a-zA-Z0-9]+\.[a-zA-Z0-9]+@(?:lseg|refinitiv)\.com$/gm)
      if (regEx.test(this.$refs.username.value) && this.$refs.password.value === 'isituseful?') {
        this.loggedIn = true
        localStorage.setItem(`${this.appName}.lastLoggedIn`, new Date().getTime())
        this.dismissNotification()
        // Usage tracking
      }
      else {
        error('Invalid username or password')
      }
    },
    logout() {
        this.currentUser = null
        this.loggedIn = false
        this.loggedOut = true
        localStorage.removeItem('jwt')
    },
    dismissNotification () {
      const notification = document.querySelector('ef-notification-tray')
      if (notification !== null) {
        const parent = notification.parentElement
        const html = document.querySelector('html')
        parent.removeChild(notification)
        html.style.removeProperty('padding-top')
        html.style.removeProperty('box-sizing')
      }
    },
    mainLayoutToggleButtonClicked () {
      this.$refs.mainLayout.collapsed = !this.$refs.mainLayout.collapsed
      this.$refs.mainLayoutToggleButton.setAttribute('icon', this.$refs.mainLayout.collapsed ? 'leftpanel-closed' : 'leftpanel-open')
    },
    renderInstrumentsGrid () {
      const rowSelection = new RowSelection()
      const config = {
        sorting: {
          threeStatesSorting: true
        },
        columns: this.equityColumns,
        dataModel: {
          fields: this.equityColumns.map(column => column.field),
          data: this.generateInstrumentItems()
        },
        rowSelection: {
          basedOnContent: true,
          selectionChanged: (event) => {
            const selectedRow = event.api.getRowData(event.selectedRows)
            console.log(selectedRow)
            this.selectedQuotePermId  = selectedRow["int_quote_perm_id"]
            this.selectedIssuePermId = selectedRow["int_issue_perm_id"]
            this.selectedRIC = selectedRow["int_ric"]
            this.rowClickFlag = [0,0,0]
            this.selectedInstrumentName = selectedRow["int_security_description"]
            this.$refs.instrumentDialog.opened = true
            this.switchTab({
              target: {
                label: 'Reference'
              }
            })
            // Create random sample data
            // this.sampleEquityReferenceChanges = this.generateEquityReferenceChangesItems()
            this.sampleEquityCorporateActionsReferenceChanges = this.generateEquityCorporateActionsReferenceChangesItems(this.getRandomInt(3, 5))
          }
        },
        extensions: [new RowGrouping(), rowSelection, new ContentWrap(), new TitleWrap()]
      }
      if (this.$refs.instrumentsGrid) {
        this.$refs.instrumentsGrid.config = config
      }
    },

    postQueryExecution(payload) {
      this.loading = true
      dataServices.queryExecution(payload).then((data) => {
          if(data["status"] == "OK"){
            this.getQueryResult(data["data"]["result"])    
          }else{
            console.log(data)
            if("Message" in data["data"]){
              error(data["data"]["Message"])
            }else{
              error(data["data"]["error"]["errorMessage"])
              // error(data)
            }
            this.loading = false
            return []
          }
      })
    },

    getQueryResult(result_id){
      dataServices.queryResult(result_id).then((result) => {
        console.log(result["data"])
        if(result["data"]["result"]["status"] == "SUCCEEDED"){
          this.returnResult(result["data"]["result"]["data"])
        }else{
          setTimeout(() => 
            this.getQueryResult(result_id), 
            this.query_request_delay
          );
        }
      })
    },

    returnResult(result){
      // if(this.refPromise>0 && result.length==0){
      //   this.requestType = "refChanges"
      // }
      console.log("result",this.requestType);
      if(this.requestType == "equity"){
        this.equityData = result
        this.renderInstrumentsGrid()
        this.loading = false
      }else if(this.requestType == "reference"){
        this.refPromise -= 1
        this.refFieldsData = result
        this.refFieldsFlag = true
        this.requestType = "refChanges"
      }else if(this.requestType == "refChanges"){
        this.refPromise -= 1
        this.requestType = "reference"
        this.refChangesData = result;
        this.renderReferenceChangesGrid()
        this.loading = false
      }else if(this.requestType == "pricing"){
        this.promiseFlag -= 1
        if(this.promiseFlag == 1){
          this.pricingData = result
        }else if(this.promiseFlag == 0){
          this.pricingData = this.pricingData.concat(result)
          this.renderPricingGrid()
          this.renderPricingChart()
        }
        this.loading = false
      }
    },

    generateInstrumentItems () {
      const items = []
      const data = this.equityData
      
      for (let i = 0; i < data.length; i++) {
        items.push([
          data[i]["int_ric"],
          data[i]["int_security_description"],
          data[i]["int_company_short_name"],
          data[i]["int_asset_status"],
          data[i]["int_currency_code"],
          data[i]["int_cusip"],
          data[i]["int_isin"],
          data[i]["int_sedol"],
          data[i]["int_trbc_industry_code"],
          data[i]["int_share_class"],
          data[i]["int_quote_perm_id"],
          data[i]["int_issue_perm_id"]
        ])
      }
      if(items.length == 0){
        this.equityResults = false;
      }else{
        this.equityResults = true;
      }
      return items
    },

    equityReferenceFieldData(){
      const refData = this.refFieldsData[0]
      const rows = []
      let columns = []
      var i = 0
      for(let data in refData){
        columns.push(data)
        columns.push(refData[data])
        if (i % 2 === 1) {
          rows.push(columns)
          columns = []
        }
        i = i+1
      }
      if (columns.length > 0 && rows[rows.length - 1] != columns) {
        rows.push(columns)
      }
      return rows
    },

    renderReferenceChangesGrid () {
      const config = {
        sorting: {
          threeStatesSorting: true
        },
        columns: this.equityReferenceChangeColumns,
        dataModel: {
          fields: this.equityReferenceChangeColumns.map(column => column.field),
          data: this.generateEquityReferenceChangesItems()
        },
        extensions: [new ContentWrap(), new TitleWrap()]
      }
      if (this.$refs.referenceChangesGrid) {
        this.$refs.referenceChangesGrid.config = config
      }
    },
    generateEquityReferenceChangesItems () {
      const items = []
      const refData = this.refChangesData
      const fields = refChangesFields
      var refData2 = this.refFieldsData
      for (let i = 0; i < refData.length; i++) {
        fields.forEach(function (field){
          if(refData[i]["ref_"+field+"_change_flag"] == "Y"){
            if(refData2.length > 0){
              refData2[0]["int_"+field] = refData[i]["ref_"+field]
            }
              
            items.push([
              refData[i]["ref_dsp_st_publish_date"],
              `Update`,
              field,
              refData[i]["ref_"+field],
              refData[i]["ref_"+field+"_previous"]
            ])
          }
        })
      }
      if(items.length == 0){
        this.refChangesFlag = false;
      }
      this.sampleEquityReferenceChanges = items
      this.refFieldsData = refData2
      return items
    },
    renderPricingGrid () {
      const config = {
        sorting: {
          threeStatesSorting: true
        },
        columns: this.equityPricingColumns,
        dataModel: {
          fields: this.equityPricingColumns.map(column => column.field),
          data: this.generateEquityPricingItems()
        },
        extensions: [new ContentWrap(), new TitleWrap()]
      }
      if (this.$refs.pricingGrid) {
        this.$refs.pricingGrid.config = config
      }
    },
    renderPricingChart(){
      const price_data = this.pricingData
      let chartFlag = 0
      if(Number(price_data[0]["prc_open_price"])<0 || Number(price_data[0]["prc_high_price"])<0 || Number(price_data[0]["prc_low_price"])<0 || Number(price_data[0]["prc_universal_close_price"])<0){
        chartFlag = 1
      }
      if((Number(price_data[0]["prc_ask_price"])<0 || Number(price_data[0]["prc_bid_price"])<0 || Number(price_data[0]["prc_mid_price"])<0) && chartFlag == 1 ){
        chartFlag = 2
      }
      function formatDate(date){
        let d = date.slice(0,4)+"-"+date.slice(4,6)+"-"+date.slice(6,8)
        return d
      }
      function uniq(d) {
        return d.sort(function compare(a, b) {
          var dateA = new Date(a.time);
          var dateB = new Date(b.time);
          return dateA - dateB;
        }).filter(function(item, pos, ary) {
            return !pos || item["time"] != ary[pos - 1]["time"];
        });
      }
      if(chartFlag == 0){
        let data = []
        let data2 = []
        for (let i = 0; i < price_data.length; i++) {
          data.push(
            {
              time: formatDate(price_data[i]["prc_trade_date"]),
              open: Number(price_data[i]["prc_open_price"]),
              high: Number(price_data[i]["prc_high_price"]),
              low: Number(price_data[i]["prc_low_price"]),
              close: Number(price_data[i]["prc_universal_close_price"])
            }
          )
          data2.push({ time: formatDate(price_data[i]["prc_trade_date"]), value: Number(price_data[i]["prc_volume"]) })
        }
        data = uniq(data)
        data2 = uniq(data2)
        console.log(data)
        const chart = document.getElementById("pricing_chart");
        chart.config = {
          series: [
            {
              type: "bar",
              data: data
            },
            {
              type: 'volume',
              seriesOptions : {
                priceScaleId: '',
                priceFormat: {
                  type: 'volume'
                },
                lineWidth: 3,
              },
              data: data2
            }
          ]
        };
        setTimeout(() => {
          chart.chart.priceScale('').applyOptions({
            scaleMargins: {
              top: 0.9,
              bottom: 0
            },
          });
        }, 800)
      }else if(chartFlag == 1){
        let data = []
        let data2 = []
        for (let i = 0; i < price_data.length; i++) {
          data.push({ time: formatDate(price_data[i]["prc_trade_date"]), value: Number(price_data[i]["prc_ask_price"]) })
          data2.push({ time: formatDate(price_data[i]["prc_trade_date"]), value: Number(price_data[i]["prc_bid_price"]) })
        }
        data = uniq(data)
        data2 = uniq(data2)
        const chart = document.getElementById("pricing_chart");
        chart.config = {
          series: [
            {
              type: 'line',
              data: data
            },{
              type: 'line',
              data: data2
            }
          ]
        };
      }else{
        this.priceChartFlag = false
      }
    },
    generateEquityPricingItems () {
      const items = []
      const price_data = this.pricingData
      function formatDate(date){
        let d = date.slice(0,4)+"-"+date.slice(4,6)+"-"+date.slice(6,8)
        return d
      }
      for (let i = 0; i < price_data.length; i++) {
        items.push([
          formatDate(price_data[i]["prc_trade_date"]),
          price_data[i]["prc_ask_price"],
          price_data[i]["prc_bid_price"],
          price_data[i]["prc_open_price"],
          price_data[i]["prc_high_price"],
          price_data[i]["prc_low_price"],
          price_data[i]["prc_universal_close_price"],
          price_data[i]["prc_volume"]
        ])
      }
      return items
    },
    renderCorporateActionsReferenceChangesGrid () {
      const config = {
        sorting: {
          threeStatesSorting: true
        },
        columns: this.equityReferenceChangeColumns,
        dataModel: {
          fields: this.equityReferenceChangeColumns.map(column => column.field),
          data: this.sampleEquityCorporateActionsReferenceChanges
        },
        extensions: [new ContentWrap(), new TitleWrap()]
      }
      if (this.$refs.corporateActionsReferenceChangesGrid) {
        this.$refs.corporateActionsReferenceChangesGrid.config = config
      }
    },
    generateEquityCorporateActionsReferenceChangesItems (maxItems) {
      const items = []
      for (let i = 0; i < maxItems; i++) {
        items.push([
          `Timestamp ${i + 1}`,
          `Update`,
          `${this.equityCorporateActionsReferenceFields[this.getRandomInt(0, this.equityCorporateActionsReferenceFields.length - 1)]}`,
          `Previous value ${i + 1}`,
          `Current value ${i + 1}`
        ])
      }
      return items
    },
    renderCorporateActionsEventsGrid () {
      const rowSelection = new RowSelection()
      const config = {
        sorting: {
          threeStatesSorting: true
        },
        columns: this.equityCorporateActionsEventColumns,
        dataModel: {
          fields: this.equityCorporateActionsEventColumns.map(column => column.field),
          data: this.generateEquityCorporateActionsEventItems(20)
        },
        rowSelection: {
          basedOnContent: true,
          selectionChanged: (event) => {
            const selectedRow = event.api.getDataRows(event.selectedRows)[0]
            console.log(selectedRow)
            this.sampleEquityCorporateActionsEventChanges = this.generateEquityCorporateActionsEventChangesItems(this.getRandomInt(5, 10))
            this.renderCorporateActionsEventChangesGrid()
          }
        },
        extensions: [new ContentWrap(), new TitleWrap(), rowSelection],
        whenDefined: () => {
          rowSelection.selectSingleRow(0)
          this.renderCorporateActionsEventChangesGrid()
        }
      }
      if (this.$refs.corporateActionsEventsGrid) {
        this.$refs.corporateActionsEventsGrid.config = config
      }
    },
    generateEquityCorporateActionsEventItems (maxItems) {
      const items = []
      const levels = ['A', 'Q', 'A', 'A', 'Q']
      const paritions = ['Restructure', 'AdditionalInformation', 'CorporateActionDetails', 'GeneralDetails', 'IntermediateSecurities', 'MarketDetails']
      for (let i = 0; i < maxItems; i++) {
        items.push([
          `Event ${i + 1}`,
          levels[this.getRandomInt(0, levels.length - 1)],
          paritions[this.getRandomInt(0, paritions.length - 1)],
          `Parent ${i + 1}`,
          i + 1,
          `Adjustment Factor ${i + 1}`,
          `Announcement Date ${i + 1}`,
          `Effective Date ${i + 1}`,
          `Meeting Date ${i + 1}`,
          `Meeting End Date ${i + 1}`,
          `Meeting Agenda ${i + 1}`,
          `Ex Date ${i + 1}`,
          `Payment Date ${i + 1}`,
        ])
      }
      return items
    },
    renderCorporateActionsEventChangesGrid () {
      const config = {
        sorting: {
          threeStatesSorting: true
        },
        columns: this.equityReferenceChangeColumns,
        dataModel: {
          fields: this.equityReferenceChangeColumns.map(column => column.field),
          data: this.sampleEquityCorporateActionsEventChanges
        },
        extensions: [new ContentWrap(), new TitleWrap()]
      }
      if (this.$refs.corporateActionsEventChangesGrid) {
        this.$refs.corporateActionsEventChangesGrid.config = config
      }
    },
    generateEquityCorporateActionsEventChangesItems (maxItems) {
      const items = []
      for (let i = 0; i < maxItems; i++) {
        items.push([
          `Timestamp ${i + 1}`,
          `Update`,
          `${this.equityCorporateActionsEventFields[this.getRandomInt(0, this.equityCorporateActionsEventFields.length - 1)]}`,
          `Previous value ${i + 1}`,
          `Current value ${i + 1}`
        ])
      }
      return items
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    getRandomFloat(min, max) {
        const val = Math.random() * (max - min) + min;
        return Math.round(val * 10**4) / 10**4
    },
    closePopup () {
      this.$refs.instrumentDialog.opened = false;
    },
    switchTab (event) {
      console.log("switch",event.target.label)
      switch (event.target.label.toLowerCase()) {
        case 'reference':
          this.referenceActive = true
          this.corporateActionsActive = false
          this.pricingActive = false
          this.$nextTick(() => {
            if(this.rowClickFlag[1] == 0){
              this.rowClickFlag[1] = 1
              this.refFieldsFlag = false
              this.requestType = "reference"
              var payload = JSON.parse(JSON.stringify(refFieldsPayload))
              payload["dataConditions"]["conditions"][0]["fieldValue"] = this.selectedIssuePermId
              payload["dateConditions"][0]["startDate"] = this.selectedDate
              payload["dateConditions"][0]["endDate"] = this.todayDate
              var payload2 = JSON.parse(JSON.stringify(refChangesPayload))
              payload2["dataConditions"]["conditions"][0]["fieldValue"] = this.selectedRIC
              payload2["dateConditions"][0]["startDate"] = this.selectedDate
              payload2["dateConditions"][0]["endDate"] = this.todayDate
              this.refPromise = 2
              Promise.all([this.postQueryExecution(payload), this.postQueryExecution(payload2)])
            }else{
              this.renderReferenceChangesGrid()
            }
          })
          break
        case 'corporate actions':
          this.referenceActive = false
          this.corporateActionsActive = true
          this.pricingActive = false
          this.$nextTick(() => {
            this.renderCorporateActionsReferenceChangesGrid()
            this.switchCorporateActionsTab({
              target: {
                label: 'Reference'
              }
            })
          })
          break
        case 'pricing':
          this.referenceActive = false
          this.corporateActionsActive = false
          this.pricingActive = true
          if(!this.priceChartFlag){
            this.priceChartFlag = true
          }
          this.$nextTick(async() => {
            if(this.rowClickFlag[2] == 0){
              this.rowClickFlag[2] = 1
              this.requestType = "pricing"
              var payload = JSON.parse(JSON.stringify(pricePayload))
              const end = new Date();
              end.setDate(end.getDate() - 2)
              const start = new Date();
              start.setDate(start.getDate() - 11)
              payload["dataConditions"]["conditions"][0]["fieldValue"] = this.selectedQuotePermId
              payload["dateConditions"][0]["startDate"] = start.toJSON().slice(0, 10);
              payload["dateConditions"][0]["endDate"] = end.toJSON().slice(0, 10);
              var payload2 = JSON.parse(JSON.stringify(pricePayload))
              const end2 = new Date();
              end2.setDate(end2.getDate() - 12)
              const start2 = new Date();
              start2.setDate(start2.getDate() - 21)
              payload2["dataConditions"]["conditions"][0]["fieldValue"] = this.selectedQuotePermId
              payload2["dateConditions"][0]["startDate"] = start2.toJSON().slice(0, 10);
              payload2["dateConditions"][0]["endDate"] = end2.toJSON().slice(0, 10);
              this.promiseFlag = 2
              Promise.all([this.postQueryExecution(payload), this.postQueryExecution(payload2)])
            }else{
              this.renderPricingGrid()
            }
          })
          break
      }
    },
    switchCorporateActionsTab (event) {
      switch (event.target.label.toLowerCase()) {
        case 'reference':
          this.corporateActionsReferenceActive = true
          this.corporateActionsEventsActive = false
          this.$nextTick(() => {
            this.renderCorporateActionsReferenceChangesGrid()
          })
          break
        case 'events':
          this.corporateActionsReferenceActive = false
          this.corporateActionsEventsActive = true
          this.$nextTick(() => {
            this.renderCorporateActionsEventsGrid()
          })
          break
      }
    },
    async loadMockData () {
      const equityReferenceFields = await staticFetch.retrieveStaticData(`data/reference-fields.json`)
      this.equityReferenceFields = equityReferenceFields
      // this.sampleEquityReferenceChanges = this.generateEquityReferenceChangesItems()
      const equityCorporateActionsReferenceFields = await staticFetch.retrieveStaticData(`data/corporate-actions-reference-fields.json`)
      this.equityCorporateActionsReferenceFields = equityCorporateActionsReferenceFields
      this.sampleEquityCorporateActionsReferenceChanges = this.generateEquityCorporateActionsReferenceChangesItems(this.getRandomInt(3, 5))
      const equityCorporateActionsEventFields = await staticFetch.retrieveStaticData(`data/corporate-actions-event-fields.json`)
      this.equityCorporateActionsEventFields = equityCorporateActionsEventFields
      this.sampleEquityCorporateActionsEventChanges = this.generateEquityCorporateActionsEventChangesItems(this.getRandomInt(5, 10))
    },
    highlightStyles (type, column) {
      return this[type].indexOf(column) !== -1 ?
        `background-color: ${this.highlightColor}; color: ${this.fontColor};`:
        ''
    },
    checkLogin () {
      const jwt = localStorage.getItem('jwt')
      console.log(jwt)
      if (jwt == null) {
        let uri = window.location.href.split('/');
        if(uri.length>4){
          this.fetchResponse()
        }else{
          this.redirectUserLogin()
        }
      }else{
        this.extract(jwt)
      }
    },
    enableSearch(){
      this.$refs.search.disabled = false;
    },
    resetSelectedValues(){
      this.$refs.search.value = "";
      this.$refs.criteria.value = "";
      this.$refs.markets.values = [];
      this.$refs.search.disabled = true;
      var payload = equityPayload
      for(let i=0;i<8;i++){
        payload["dataConditions"]["conditions"][i]["fieldValue"] = "EMPTY"
        payload["dataConditions"]["conditions"][i]["fieldOperator"] = "1"
      }
    },
    saveSelectedValues () {
      const fileCodes = {
        "HK": "1|66|3200|3508",
        "JP": "2|6|4|7",
        "SG": "27",
        "TH": "17|760|3953",
        "US": "77|1174|1175|81|79",
        "AU": "66",
        "CA": "82|4912|1676",
        "FR": "52|3929",
        "GE": "1693|62|60|186",
        "IN": "47",
        "LN": "43|691|1144|1215|482|1560",
        "PH": "250",
        "SW": "477",
        "NL": "50",
        "IND": "190|423"
      };
      const search = this.$refs.search.value
      const crite = this.$refs.criteria.value
      this.selectedDate = this.$refs.datePicker.value
      this.selectedMarket = this.$refs.markets.values
      this.highlightColor = this.$refs.highlightColor.value
      this.fontColor = this.$refs.fontColor.value
      this.requestType = "equity"
      var payload = equityPayload
      if(search.length>0 && crite != ""){
        payload["dataConditions"]["conditions"][Number(crite)]["fieldValue"] = search
        payload["dataConditions"]["conditions"][Number(crite)]["fieldOperator"] = "0"
      }
      if(this.selectedMarket.length>0){
        var fileCodesString = "";
        for(let market of this.selectedMarket){
          fileCodesString += fileCodes[market]+"|"
        }
        fileCodesString = fileCodesString.slice(0,-1);
        payload["dataConditions"]["conditions"][6]["fieldValue"] = fileCodesString;
        payload["dataConditions"]["conditions"][6]["fieldOperator"] = "0"
      }
      payload["dateConditions"][0]["startDate"] = this.selectedDate
      payload["dateConditions"][0]["endDate"] = this.todayDate
      this.postQueryExecution(payload)
    },
    toBeImplement () {
      info('This feature has not been implemented.')
    }
  },
  mounted () {
    this.loadMockData()
    localStorage.setItem('appId', this.appName)
    setTimeout(() => {
      this.ready = true
      this.checkLogin()
    }, 100)
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
header, nav {
  margin: 0;
  padding: 0;
  font-size: 15px;
  width: 100%;
  overflow: visible;
}
header {
  height: 79px;
  background-color: #fff;
}
nav.main {
  background-color: #ffffff;
}
nav.main ul {
  margin: -10px 0 0 0;
  padding: 0;
}
nav.main li {
  list-style: none;
  display: inline-block;
  overflow: visible;
}
nav.main a {
  color: #404040;
  display: inline-block;
  padding: 20px;
  text-decoration: none;
  vertical-align: bottom;
  box-sizing: content-box;
  border-bottom:  5px solid #fff;
}
nav.main a:hover {
  border-bottom:  5px solid #001eff;
}
nav.main a.active {
  color: #404040;
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  font-weight: 700;
  outline: 0;
  border-bottom:  5px solid #001eff;
}
nav.main a.logo {
  width: 155px;
  height:  40px;
  display: inline-block;
  background-color: transparent;
  border-radius: 0;
  margin-right: 50px;
  border-bottom:  5px solid #fff;
}
a.logo span {
  display: inline-block;
  background-image: url(assets/refinitiv.logo.strapline.svg);
  background-repeat: no-repeat;
  background-size: 80%;
  text-indent: -9999px;
  width: 198px;
  height: 78px;
}
ef-sidebar-layout {
  height: calc(100vh - 79px);
}
.full {
  width: 100%;
}
ul.input-panel, .horizontal-option {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
ul.input-panel li {
  margin-bottom: 10px;
}
ul.input-panel li:last-child {
  margin-bottom: 0;
}
ul.input-panel label {
  display: inline-block;
  padding-bottom: 5px;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  cursor: pointer;
  pointer-events: none;
}
efx-grid {
  /*height: calc(100vh - 490px);*/
  height: calc(100vh - 110px)
}
.popup {
  height: 80vh;
  width: 80vw;
  display: flex;
  justify-content: center;
}
.horizontal-option li {
  display: inline-block;
  width: 50%;
}
.info-table td {
  text-align: left;
}
.info-table td.header {
  width:15%;
  font-weight: bold;
  background-color: #ccc;
  color: #000;
  border: 1px solid #aaa;
}
.info-table td.value {
  width:35%;
  background-color: #fff;
  color: #000;
  border: 1px solid #aaa;
}
ef-panel.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  /*height: 500px;*/
  margin: 15% auto;
  border: solid 1px #e1e1e1;
  /* From XD */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}
.login .logo {
  margin: 30px;
  height: 60px;
}
.login ef-text-field, .login ef-password-field {
  height: 33px;
  width: 100%;
  margin-bottom: 15px;
}
.login ef-button.large {
  /*background-color: #001EFF;*/
  height: 33px;
  min-width: 100px;
  font-size: 120%;
  color: #FFFFFF;
}
.w150 {
  width: 150px;
}
.toolbar-element {
  margin-left: 10px;
}
.right {
  text-align: right;
}
.mb30 {
  margin-bottom: 30px;
}
.logout{
  float: right;
  margin-top: 40px;
  margin-right: 20px;
}
.no-results-p{
  text-align: center;
  padding: 10px;
}
</style>
